<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <sgv-input-select
            :options="categories"
            v-model="selectedCategory"
            select="value"
            class="col-auto">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="papers"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td
            v-if="hidden.code"
            @click="toDetail(item.id)"
            class="pointer"
            style="white-space: nowrap;"
            :class="{
              'text-danger': !item.isActive,
              'text-warning': !item.approvedBy
            }">
            {{ item.code }}
          </td>
          <td v-if="hidden.name">
            {{ item.name }}
            <small>
              <div
                v-for="txt in mapConfig(item.topics)"
                :key="txt"
                class="text-info">
                <fa icon="sync"></fa>{{txt}}
              </div>
            </small>
          </td>
        </tr>
      </template>

      <button
        v-if="$auth.hasRole(`paper:${paperType}:add`)"
        type="button"
        class="btn btn-link text-success"
        slot="action"
        @click="toDetail(0)">
        เพิ่ม
      </button>

    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PAPER } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      papers: [],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      detailView: `Paper${this.$form.capitalize(this.paperType)}Detail`,
      rKey: `Paper${this.$form.capitalize(this.paperType)}List`,
      rFields: ['filter', 'options', 'selectedCategory'],
      categories: [
        {text: 'ใช้งาน', value: 'active'},
        {text: 'ยกเลิก', value: 'inactive'},
      ],
      selectedCategory: 'active'
    }
  },
  apollo: {
    papers: {
      query () {
        return LIST_PAPER(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          paperType: this.paperType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: {
          ...v.params,
          type: this.paperType,
          isActive: this.selectedCategory === 'inactive' ? false : true 
        }
      }
    },
    toDetail (paperId) {
      this.$router.push({
        name: this.detailView,
        params: {paperId}
      })
    },
    mapConfig (topics) {
      const arr = topics
      .flatMap(v => v.docConfigActionPlans)
      .map(v => `${v.docType}: ${v.type}`)

      return [...new Set(arr)]
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
